
const CompanyInput = () => import("@/components/form/CompanyInput.vue").then(m => m.default || m)
const PrimaryContactInput = () => import("@/components/form/PrimaryContactInput.vue").then(m => m.default || m)
const FormStepHeader = () => import("@/components/form/FormStepHeader.vue").then(m => m.default || m)

import Vue from "vue"
import { CreateTenantErrorResponse, CreateTenantPayload, SnackbarInfo } from "@/types/responses"  
import { CreateTenantErrorTypes } from "@/types/enums"

export default Vue.extend({
	name: "CreateOrganization",
	components: {
        CompanyInput,
        PrimaryContactInput,
        FormStepHeader
	},
	data(): {
		loading: boolean
        // Error messages
        companyInputError: CreateTenantErrorResponse | null
        primaryContactError: CreateTenantErrorResponse | null

	} {
		return {
			loading: false,
            companyInputError: null,
            primaryContactError: null
			}
	},
    computed: {
        isCreateTenantFormValid(): boolean {
            const formdata = this.$store.state.context.createTenantFormData
            if(this.isFirstStepCompleted && formdata.email && formdata.phoneNumber){
                return true
            }
            return false
        },
        isFirstStepCompleted(): boolean {
            const formdata = this.$store.state.context.createTenantFormData
            const nameValid = formdata.companyName
            //Orgnumber is valid if it is left out altogether or it is a string with lengt 9 consisting of only digits
            const orgNumberValid = (formdata.businessRegistryId === "" || (formdata.businessRegistryId.length === 9 && /^\d+$/.test(formdata.businessRegistryId) ))
            if(nameValid && orgNumberValid) {
                return true
            }
            return false
        },

    },
	methods: {
        async createTenant(): Promise<void> {
            this.loading = true
            let snackBarMessage: SnackbarInfo
            const createTenantPayload = await this.$store.state.context.createTenantFormData
            const formIsValid: boolean = this.validatePayload(createTenantPayload)
            if(createTenantPayload && formIsValid){

                console.log("[CreateOrganization.createTenant] Creating tenant: ", createTenantPayload)
                try {
                    const res = await this.$store.dispatch("context/createTenant", createTenantPayload)
                    if(res?.response?.status === 400 ?? false){
                        const errorMessage: CreateTenantErrorResponse = res.response.data.errors ?? null

                        if(errorMessage){
                            this.setErrorMessage(errorMessage)
                        }
                        console.log("[CreateOrganization.createTenant] ErrorMessage: ", errorMessage)
                        snackBarMessage= {
                            success: false,
                            msg: "One or more validation errors occured when sending in form to create the organization " + createTenantPayload.companyName,
                            e: null
                        }
                        this.$emit('updateSnackBar', snackBarMessage)
                    }else{
                        snackBarMessage= {
                            success: true,
                            msg: "Successfuly sent the request to create the new organization " + createTenantPayload.companyName,
                            e: null
                        }
                        
                    this.$emit('updateSnackBar', snackBarMessage)
                    this.$emit('changeTab')
                    }
                }catch (e){

                    snackBarMessage= {
                        success: false,
                        msg: "Failed to get a response from server, when attempting to create the organization " + createTenantPayload.companyName + ". Please try again in a few moments. \n Error message: " + e,
                        e: e
                    }
                    this.$emit('updateSnackBar', snackBarMessage)
                }

            }
            this.loading = false
        },
        validatePayload(payload: CreateTenantPayload): boolean{
            
            if(payload.companyName){
                const emailIsValid = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(payload.email)
                const phoneIsValid = /^(?:.*\d){8,}$/.test(payload.phoneNumber)
                const orgnumberIsValid1 = /^\d{9}$/.test(payload.businessRegistryId)
                const orgNumberIsValid2 =  payload.businessRegistryId === ""
                if(emailIsValid && phoneIsValid && (orgnumberIsValid1 || orgNumberIsValid2)){
                    return true
                }
            }
            return false
        },

        setErrorMessage(errorMessage: CreateTenantErrorResponse): void{
            
            if(Object.hasOwn(errorMessage, CreateTenantErrorTypes.businessRegistryId) || Object.hasOwn(errorMessage, CreateTenantErrorTypes.companyName)){
                this.companyInputError = errorMessage
                this.primaryContactError = errorMessage
            }

            if(Object.hasOwn(errorMessage, CreateTenantErrorTypes.email) || Object.hasOwn(errorMessage, CreateTenantErrorTypes.phoneNumber)){
                this.primaryContactError = errorMessage
            }

            

        }
	},

})
